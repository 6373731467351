<script>
import ContentBlockMixin from '@shared/mixins/ContentBlock';
import ContentBlockEmbedoView from '@shared/components/ContentBlocks/Views/Embedo.vue';

export default {
  mixins: [ContentBlockMixin],
  components: { ContentBlockEmbedoView },
  static: {
    data: { url: '', caption: '' },
  },
  data: () => ({ isForm: true }),
  computed: {
    type() {
      return this.contentBlock.type.toUpperCase();
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <b-field :label="`Lien ${type} *`">
        <b-input v-model="newData.url" required />
      </b-field>
      <b-field label="Légende">
        <b-input v-model="newData.caption" />
      </b-field>
    </template>
    <template #view>
      <ContentBlockEmbedoView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
